import Particles from "react-tsparticles";
// import { loadSlim } from "tsparticles-slim"; // loads tsparticles-slim
import { loadFull } from "tsparticles"; // loads tsparticles
import { useCallback } from "react"; // useMemo

// tsParticles Repository: https://github.com/matteobruni/tsparticles
// tsParticles Website: https://particles.js.org/
const ParticlesComponent = (props) => {
    const particleParams = require('./particles.json');

    const particlesInit = useCallback((engine) => {
        // loadSlim(engine);
        loadFull(engine); // for this sample the slim version is enough, choose whatever you prefer, slim is smaller in size but doesn't have all the plugins and the mouse trail feature
    }, []);

  return <Particles id={props.id} init={particlesInit} options={particleParams} />;
};

export default ParticlesComponent;
