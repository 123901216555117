import React, { useState, useEffect } from "react";
import { Link, useLocation } from "wouter";
export default function Home() {


return (
    <>  
        <title>Grimm's Corner</title>
        <main id="main" className="">
            <div className="cardHolderCast">
                <div className="castCardFull card">
                    <img src='images/pfp/grimm.jpg' alt="Profile of GrimmXIII"/>
                    <h3>GrimmXIII</h3>
                    <Link href='/grimmWeb'><button>Web Design</button></Link>

                </div>

                <div className="castCardEmpty card">
                    <img src='images/pfp/empt.jpg' alt="Empty Profile"/>
                    <h3>&nbsp;</h3>
                    <p className='emptBlock'>
                        Empty Slot
                    </p>
                </div>
            </div>

            <div className="cardHolderCast">
                <div className="castCardEmpty card">
                    <img src='images/pfp/empt.jpg' alt="Empty Profile"/>
                    <h3>&nbsp;</h3>
                    <p className='emptBlock'>
                        Empty Slot
                    </p>
                </div>

                <div className="castCardEmpty card">
                    <img src='images/pfp/empt.jpg' alt="Empty Profile"/>
                    <h3>&nbsp;</h3>
                    <p className='emptBlock'>
                        Empty Slot
                    </p>
                </div>
            </div>

        </main>
    </>
    );
}
