import React from "react";
// import React, { useState, useEffect } from "react";
// import { Link, useLocation } from "wouter";
export default function Home() {


return (
    <>  
        <title>Grimm's Corner</title>
        <main id="main" className="">
            <div className="logoCont">
                <img src='images/logoWhite.png' alt="GrimmBytes Logo"/>
            </div>

            <div className='warning'>
                <img src='images/warning.png' />
                <p>
                    Website is still a work in progress.
                </p>
                <img src='images/warning.png' />
            </div>

            <p className='mainPara'>
                GrimmBytes is the head all, ment to represent anything or any project started by one of the Grimm Services
            </p>

            <div className="cardHolderService">
                <div className="serviceCard">
                    <h2>GrimmWare</h2>
                    <p>
                        Typically game modifications, bots, or software  created here.
                    </p>
                
                </div>
                
                <div className="serviceCard">
                    <h2>Grimm Productions</h2>
                    <p>
                        Public Videos, Games, or Photos came from here.
                    </p>
                
                </div>
                
                <div className="serviceCard">
                    <h2>Grimm's Tech Repair</h2>
                    <p>
                        Repairing and Reselling tech.
                    </p>
                
                </div>
            </div>


            <h1>What we create?</h1>
            <div className="cardCreate">
                <h2>Videos</h2>
                <div className="">
                    <img src='images/create/place.png' alt="Screenshot of Video"/>
                    <p>
                        We have a channel, well, GrimmXIII has a channel. All videos are editited with Filmora
                    </p>
                </div>
            </div>

            <div className="cardCreate">
                <h2>Software</h2>
                <div className="">
                    <img src='images/create/place.png' alt="Screenshot of software made in Python"/>
                    <p>
                        We are trying to learn more languages, beginning with Python. We hope to make utilities that could help people with computer issues, or to automate tasks.
                    </p>
                </div>
            </div>

            <div className="cardCreate">
                <h2>Websites</h2>
                <div className="">
                    <img src='images/create/website.png' alt="Screenshot of a Portfolio Website"/>
                    <p>
                        We are very fluent in pure HTML and React.JS. While we don't work on Back-End stuff yet, we can get a website up and running just the way you want it! (Back-End Support will be here one day)
                    </p>
                </div>
            </div>

            <h1>Meet the Cast</h1>
            <div className="cardHolderCast">
                <div className="castCardFull card">
                    <img src='images/pfp/grimm.jpg' alt="Profile of GrimmXIII"/>
                    <h3>GrimmXIII</h3>
                    <p>
                        GrimmXIII's main skill is the development part. When it comes to creation of code, Grimm likely made it. However, he sucks ass at creating images or music. Can't be the jack of all trades :(
                    </p>
                </div>

                <div className="castCardEmpty card">
                    <img src='images/pfp/empt.jpg' alt="Empty Profile"/>
                    <h3>&nbsp;</h3>
                    <p className='emptBlock'>
                        Empty Slot
                    </p>
                </div>
            </div>

            <div className="cardHolderCast">
                <div className="castCardEmpty card">
                    <img src='images/pfp/empt.jpg' alt="Empty Profile"/>
                    <h3>&nbsp;</h3>
                    <p className='emptBlock'>
                        Empty Slot
                    </p>
                </div>

                <div className="castCardEmpty card">
                    <img src='images/pfp/empt.jpg' alt="Empty Profile"/>
                    <h3>&nbsp;</h3>
                    <p className='emptBlock'>
                        Empty Slot
                    </p>
                </div>
            </div>

            
        </main>
    </>
    );
}
