import React, { useState, useEffect } from "react";
import { Link, useLocation } from "wouter";
export default function Home() {

    
return (
    <>  
        <title>Grimm's Corner</title>
        <h1>
            GrimmXIII Web Design Calculator
        </h1>
        <main id="main" className="">
            <table className="calculator">
                <tr>
                    <th>
                        Initial Website Creation
                    </th>
                    <th style={{width:'50%', fontSize:'50px'}}>
                        25$<br/>
                    </th>
                    <th style={{width:'10%'}}>
                        <input type="checkbox" name="sussy" value="sussy" checked readonly/>
                    </th>

                </tr>

                <tr>
                    <th>
                        Per Page
                    </th>
                    <th style={{width:'50%', fontSize:'50px'}}>
                        10$<br/>
                    </th>
                    <th style={{width:'10%'}}>
                        <input className='typeNumber' type="number"/>
                    </th>

                </tr>

                <tr>
                    <th>
                        Custom Library Support
                    </th>
                    <th style={{width:'50%', fontSize:'50px'}}>
                        15$<br/>
                    </th>
                    <th style={{width:'10%'}}>
                        <input type="checkbox" name="sussy" value="sussy"/>
                    </th>

                </tr>

                <tr>
                    <th>
                        Domain Purchase
                    </th>
                    <th style={{width:'50%', fontSize:'50px'}}>
                        10$<br/>
                    </th>
                    <th style={{width:'10%'}}>
                        <input type="checkbox" name="sussy" value="sussy"/>
                    </th>

                </tr>

                <tr>
                    <th>
                        Hosting on Domain
                    </th>
                    <th style={{width:'50%', fontSize:'50px'}}>
                        10$ a M/O<br/>
                    </th>
                    <th style={{width:'10%'}}>
                        <input type="checkbox" name="sussy" value="sussy"/>
                    </th>

                </tr>

                <tr>
                    <th>
                        Set it up yourself assist
                    </th>
                    <th style={{width:'50%', fontSize:'50px'}}>
                        60$ + domain<br/>
                    </th>
                    <th style={{width:'10%'}}>
                        <input type="checkbox" name="sussy" value="sussy"/>
                    </th>

                </tr>





            </table>
            <div>
                <p>
                    Initial Cost - Per Month
                </p>
                <h1>
                    0$ - 0$ Per Month
                </h1>
            </div>



        </main>
    </>
    );
}
