import React, { useState, useEffect } from "react";
import { Link, useLocation } from "wouter";
export default function Home() {


return (
    <>  
        <title>Grimm's Corner</title>
        <h1>
            GrimmXIII Edition
        </h1>
        <main id="main" className="">
            
        </main>
    </>
    );
}
