import * as React from "react";
import { Switch, Route, Router } from "wouter";
import Home from "../pages/home";
import About from "../pages/about";
import Hire from "../pages/hire";

//GrimmXIII Hire
import grimmWeb from "../pages/cast/grimm/web.js";

import error from "../pages/error"


export default () => (
    <Switch>
        <Route path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/hire" component={Hire} />

        <Route path="grimmWeb" component={grimmWeb} />

        <Route component={error} />

    </Switch>
);
