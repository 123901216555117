import './styles/styles.css';
import Particles from './components/particles.js';
import PageRouter from "./components/router.js";
import { Router, Link, useLocation } from "wouter";
 
function App() {
  return (
    <Router>
        <div className="App">
            <header className="App-header">
                <nav>
                    <img src='images/logoWhiteInline.png' alt=''></img>


                    <Link href='/'><div className='navLink'><a>Coming Soon</a></div></Link>
                    <Link href='/'><div className='navLink'><a>Coming Soon</a></div></Link>
                    <Link href='/'><div className='navLink'><a>Coming Soon</a></div></Link>
                    <Link href='/'><div className='navLink'><a>Home</a></div></Link>

                </nav>
            </header>
            <PageRouter />
            <footer>
                <img src='images/logoWhiteInline.png' alt='' style={{width:'33%', margin:'10px'}}/>
                <div>
                    <Link href='/grimmxiii'><img className='footerPFP' src='images/pfp/grimm.jpg' alt=''/></Link>
                    <Link href='/'><img className='footerPFP' src='images/pfp/empt.jpg' alt=''/></Link>
                    <Link href='/'><img className='footerPFP' src='images/pfp/empt.jpg' alt=''/></Link>
                    <Link href='/'><img className='footerPFP' src='images/pfp/empt.jpg' alt=''/></Link>
                </div>
            </footer>
            <Particles id="tsparticles" />
        </div>
    </Router>
  );
}

export default App;
