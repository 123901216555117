import React, { useState, useEffect } from "react";
import { Link, useLocation } from "wouter";
export default function Home() {


return (
    <>  
        <title>Grimm's Corner</title>
        <main id="main" className="">
            <h1 className="error">
                404!
            </h1>
            <div className="errorP0">
                <p className="errorP">
                    It seems like this page doesn't exist :(<br/>
                    Maybe start your journey again?<br/>
                    ▼▼ Here is a shortcut back! ▼▼

                <Link href='/'><div className="errorButton">Warp Back to Homepage</div></Link>
                </p>
            </div>
            
        </main>
    </>
    );
}
